<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <router-link :to="'/crm-lead'" class="btn btn-outline-success mb-3">
      <i class="uil-arrow-circle-left me-1"></i> Back
    </router-link>
    <a class="btn btn-outline-warning mb-3" style="margin-left: 10px" href="javascript:window.print()">
      <i class="uil-print me-1"></i> print
    </a>

    <div class="row mb-4">
      <div class="col-xl-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <h5 class="mt-3 mb-1">{{ data.name }}</h5>
              <p class="text-muted">{{ data.type }}</p>
            </div>

            <hr class="my-4" />

            <div class="text-muted">
              <div class="table-responsive mt-4 mb-0">
                <div class="mt-4">
                  <p class="mb-1">Seller Name:</p>
                  <h5 class="font-size-16">{{ data.user_find.name }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Price :</p>
                  <h5 class="font-size-16">{{ data.price }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Pipeline Name :</p>
                  <h5 class="font-size-16">{{ data.pipeline_find.name }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Status Name :</p>
                  <h5 class="font-size-16">{{ data.status_find.name }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Loss Reason Name :</p>
                  <h5 class="font-size-16">{{ data.loss_reason }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Closed At :</p>
                  <h5 class="font-size-16">{{ data.closed_at }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Created At :</p>
                  <h5 class="font-size-16">{{ data.lead_created_at }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Country :</p>
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <h5 class="font-size-16" style="margin-top:12px;">
                          {{ data.country }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class=" col-xl-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Details</h4>
            <div class="table-responsive mt-4 mb-0" style="max-height: 300px">
              <div class="mt-4">
                <p class="mb-1">utm_source :</p>
                <h5 class="font-size-16">{{ data.utm_source }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">utm_medium :</p>
                <h5 class="font-size-16">{{ data.utm_medium }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">utm_campaign :</p>
                <h5 class="font-size-16">{{ data.utm_campaign }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">utm_content :</p>
                <h5 class="font-size-16">{{ data.utm_content }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">utm_term :</p>
                <h5 class="font-size-16">{{ data.utm_term }}</h5>
              </div> 
              <div class="mt-4">
                <p class="mb-1">campaign :</p>
                <h5 class="font-size-16">{{ data.campaign }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">source :</p>
                <h5 class="font-size-16">{{ data.lead_source }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">treatments :</p>
                <h5 class="font-size-16">{{ data.treatments }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">area body :</p>
                <h5 class="font-size-16">{{ data.area_body }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">deposit :</p>
                <h5 class="font-size-16">{{ data.deposit }}</h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">deposit date:</p>
                <h5 class="font-size-16">{{ data.deposit_date }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import moment from "moment";

import { API } from "@/state/API";

import { POPUP } from "@/state/popup";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "CRM Lead Detail",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "CRM Lead Detail",
      data: {},
      data_info: {},
      items: [
        {
          text: "CRM Lead",
        },
        {
          text: "CRM Lead Detail",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.ListLp();
  },
  methods: {
    async ListLp() {
      this.statusRefresh = 0;
      await API.Get(API.CRMLead.Detail + this.$route.params.id).then(
        (response) => {
          var data;
          if (response.data.status === "yetkiYok") {
            this.statusRefresh = 0;
            var timer = 5000;
            data = "You don't have access permission";
            POPUP.popupClickNot(data, timer);
          }
          if (response.data.status === "success") {
            this.data = response.data.data;
            this.data_info = response.data.data_info;
            console.log(this.data_info);

            this.data.created_at = moment(String(this.data.created_at)).format(
              "DD/MM/YYYY hh:mm"
            );

            data = "Your work has been refresh";
            POPUP.popupClick(data);
            this.statusRefresh = 1;
          } else {
            data = "Your work could not be renewed";
            POPUP.popupClickNot(data);
            this.statusRefresh = 1;
          }
        }
      );
    },
  },
};
</script>
